<style lang="less">
  @theme-1-color: #F4628F;
  .mobile-login-page {
    .fm-input-new-prefix-prepend .iconfont {
      margin-left: 10px;
      color: @theme-1-color;
    }
    .fm-form-item .fm-form-item-label {
      display: none;
    }
    .fm-form-item .fm-input-new {
      border-radius: 20px;
      border-color: @theme-1-color;
      width: 100%;
      background-color: #FFF;
      &.fm-input-focus {
        box-shadow: 0 0 0px 3px rgba(244, 98, 143, .15);
      }
      input:-internal-autofill-selected {
        border-radius: 20px;
        background-color: #FFF !important;
        background-image: none !important;
        color: rgb(0, 0, 0) !important;
        box-shadow: inset 0 0 0 1000px #FFF !important;
      }
      input:-webkit-autofill {
        border-radius: 20px;
        background-color: #FFF !important;
        background-image: none !important;
        box-shadow: 0 0 0px 1000px #FFF inset !important;
      } 
      input:-webkit-autofill:focus {
        border-radius: 20px;
        background-color: #FFF !important;
        background-image: none !important;
        box-shadow: 0 0 0px 1000px #FFF inset !important;
      } 
    }
  }
</style>

<style lang="less" scoped>
  .mobile-login-page {
    background-color: #CACACA;
    height: 100vh;
    overflow: auto;
  }
  .login-page-logout {
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #999;
  }
  .header {
    background-color: #FC7A9A;
    color: #FFF;
    padding-bottom: 20px;
  }
  .wrap {
    position: relative;
    top: -25px;
    min-height: calc(100vh - 76px);
    padding: 10px 24px;
    background-image: url('/static/images/mobile/login/2.png');
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: bottom right;
    background-color: #FFF;
    box-shadow: 0px -2px 3px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
  }
  .title {
    font-size: 24px;
    line-height: 29px;
    color: #FC7A9A;
  }
  .sub-title {
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    color: #343434;
    margin-top: 10px;
  }
  .login-img {
    display: block;
    margin: 36px auto 27px auto;
    width: 70%;
    max-width: 200px;
  }
  .link {
    color: #FC7A9A;
  }
  .login-btn {
    font-size: 14px;
    color: #FFF;
    border-color: #FC7A9A;
    background: #FC7A9A;
    border-radius: 40px;
  }
</style>

<template>
  <div class="mobile-login-page" v-if="!isLogout">
    <div class="header">
      <span style="padding: 18px 24px;display: block; font-weight: 20px;">登录</span>
    </div>
    <div class="wrap">
      <div class="title">欢迎你</div>
      <div class="sub-title">您好，请继续登录！</div>
      <img class="login-img" src="/static/images/mobile/login/1.png" />
      <fm-form ref="verifier-form">
        <fm-form-item>
          <fm-input-new v-model="account" v-verifier required clearable placeholder="请输入帐号">
            <i class="iconfont icon-yonghu2" slot="prefix"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item>
          <fm-input-new v-model="password" v-verifier required clearable type="password" placeholder="请输入密码">
            <i class="iconfont icon-mima-old" slot="prefix"></i>
          </fm-input-new>
        </fm-form-item>
        <fm-form-item>
          <div style="display: flex; justify-content: space-between;">
            <fm-checkbox v-model="remember" style="opacity: 0;" label="自动登录"></fm-checkbox>
            <a class="link" @click="$notice.info('请联系管理员')">忘记密码</a>
          </div>
        </fm-form-item>
        <fm-form-item style="margin-top: 10px;">
          <fm-btn class="login-btn" @click="login" :v-loadingx="loading" size="large" block>登录</fm-btn>
        </fm-form-item>
        <fm-form-item>
          <a target="_blank" style="color: #000; display: block; text-align: center;" href="https://beian.miit.gov.cn">{{ipc ? '备案号：' + ipc : ''}}</a>
        </fm-form-item>
      </fm-form>
    </div>
    <change-pwd :show="showChangePass" @changeOver="changeOver" :token="token" @close="showChangePass = false"></change-pwd>
  </div>
  <div class="mobile-login-page login-page-logout" v-else>
    注销中...
  </div>
</template>

<script>
import ChangePwd from './changePwd'
import {
  localstorageHelper
} from '@/fmlib/index'

import sysEnv from '@/env/env'

import {
  login,
  getLoginPubKey
} from '@/api'

import {
  rsaHelper
} from '@/fmlib'

import { v4 as uuidv4 } from 'uuid'

let md5 = require('js-md5')

let defaultThemeType = localStorage.getItem('defaultThemeType')
let themes = [0, 1, 2, 3]

export default {
  components: {
    ChangePwd
  },
  data () {
    return {
      ipc: sysEnv.ipc || '',
      themeType: this.clientType === 'wap' ? 1 : (defaultThemeType ? parseInt(defaultThemeType) : ~~(Math.random() * 10 % themes.length)),
      defaultThemeType: parseInt(defaultThemeType),
      account: '',
      password: '',
      isDefaultPwd: false,
      isLogout: false,
      defaultPwd: 'e10adc3949ba59abbe56e057f20f883e',
      showChangePass: false,
      remember: false,
      loading: false,
      token: ''
    }
  },
  computed: {
    themes () {
      return themes
    },
    clientType () {
      return this.$store.getters.clientType
    }
  },
  created () {
    this.isLogout = this.$router.history.current.params.logOut === 1
  },
  methods: {
    changeOver () {
      this.password = ''
      this.$notice.success({
        title: '系统提示',
        desc: '密码修改完成，请使用新密码重新登陆!'
      })
    },
    setTheme (type) {
      this.themeType = type
      if (this.defaultThemeType === type) {
        this.defaultThemeType = null
        localStorage.removeItem('defaultThemeType')
      } else {
        this.defaultThemeType = type
        localStorage.setItem('defaultThemeType', type)
      }
    },
    async login () {
      try {
        this.loading = true
        if (!this.$refs['verifier-form'].verifier()) {
          throw new Error('请正确填写表单')
        }
        let uuid = uuidv4()
        let pubKey = await getLoginPubKey({'rsaKey': uuid})
        this.isDefaultPwd = this.defaultPwd === md5(this.password)
        const result = await login({
          phone: this.account,
          password: rsaHelper.rsaEncrypt(pubKey.rsaPubKey, this.password),
          clientType: this.$store.getters.clientType === 'wap' ? 'mobile' : this.$store.getters.clientType,
          rsaKey: uuid
        })
        this.loading = false
        if (this.isDefaultPwd) {
          this.token = result.token
          this.$notice.warning({
            title: '系统提示',
            desc: '密码为初始密码，请修改后重新登录!'
          })
          this.showChangePass = true
          return
        } else {
          localstorageHelper.updateData('token', result.token)
          this.$store.dispatch('authInit')
        }
      } catch (error) {
        this.loading = false
        this.$notice.warning(error.message)
      }
    }
  }
}
</script>